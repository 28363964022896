import React from "react";
import { useStoreState } from "easy-peasy";
import { SpinnerInfinity } from "spinners-react";

const Loader = () => {
  const account = useStoreState((state) => state.account);

  return (
    <SpinnerInfinity
      size={50}
      thickness={100}
      speed={100}
      color="#000000"
      secondaryColor={account?.themeColor ? account.themeColor : "#FFE01B"}
    />
  );
};

export default Loader;
