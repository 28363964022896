import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import "./assets/scss/App.scss";


import {
  QueryClient,
  QueryClientProvider,
} from "react-query";

const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));

const queryClient = new QueryClient();

function App() {
  return (
        <QueryClientProvider client={queryClient}>
          <div className="relative z-10 ">
            <Router>
              <Switch>
                <Route key="login" path="/login" component={Login} />
                <Route key="app" path="/app" component={Layout} />
                <Redirect exact from="/" to="/login" />
              </Switch>
            </Router>
           
          </div>
         
        </QueryClientProvider>

  );
}

export default App;