import { createStore, action } from "easy-peasy";

export const store = createStore({
  loading: false,
  account: null,
  setLoading: action((state, payload) => {
    state.loading = payload;
  }),
  activeLanguage: "",
  setActiveLanguage: action((state, payload) => {
    state.activeLanguage = payload;
  }),
  setAccount: action((state, payload) => {
    state.account = payload;
  }),
});
