import React from 'react'

import SlideEffect from "./SlideEffect";


const Overlay = ({className, children, disableSlide}) => {


    return (


        <SlideEffect disableSlide={disableSlide}
                     className={"modal-wrapper fixed z-30 top-0 left-0 right-0 bottom-0 flex justify-center items-center " + className}>


            {children}





            <div className={"absolute bg-black top-0 left-0 right-0 bottom-0 opacity-50"}></div>
        </SlideEffect>


    );
}

export default Overlay;