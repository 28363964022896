import React from 'react'


import Loader from "./Loader";
import Overlay from "./Overlay";


const FullscreenLoader = ({className, disableSlide}) => {

    return (

        <Overlay className={className} disableSlide={disableSlide}>
            <div className={"z-30 relative"}><Loader/></div>
        </Overlay>
    );
}

export default FullscreenLoader;