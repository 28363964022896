import React, {Suspense} from 'react'
import ReactDOM from 'react-dom'
import './assets/css/tailwind.output.css'
import App from './App'

import { StoreProvider } from 'easy-peasy';
import { store } from './store/store';
import FullscreenLoader from "./components/Misc/FullscreenLoader";

import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

ReactDOM.render(
    <StoreProvider store={store}>
        <Suspense fallback={<FullscreenLoader className={"main"} display={"block"}/>}>
            <DndProvider backend={HTML5Backend}>
            <App/>
       
            </DndProvider>
           
        </Suspense>
    </StoreProvider>
    ,
    document.getElementById('root')
)