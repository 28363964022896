import React, {useState} from 'react'

const SlideEffect = ({disableSlide, children, className}) => {

    const [start, setStart] = useState(disableSlide);
    const [finished, setFinished] = useState(false);

    setTimeout(() => {
        setStart(true);
    }, 10);

    setTimeout(() => {
        setFinished(true);
    }, 1000);

    return (
        <div className={className + " "  + (start ? "opacity-100 mt-0" : "opacity-0 -mt-12") + (!finished && " transform transition-all duration-500 ease-out")}>
            {children}
        </div>
    )
}

export default SlideEffect;



